<template>
  <div class="header_wrapper">
    <!-- <img src="../assets/Logo.svg" alt="" class="logo" /> -->
    <!-- <div class="date">
            <img src="../assets/Dot.png" alt="" />
            <span>{{ date }}</span>
            <img src="../assets/Dot.png" alt="" />
        </div> -->
    <div class="time">{{ time }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: "00:00",
      date: "Aug 9, 2022",
      timeInterval: null,
    };
  },
  methods: {
    getTime() {
      let monthArr = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ];
      let today = new Date();
      let minute = today.getMinutes();
      let hour = today.getHours();
      let day = today.getDate();
      let month = today.getMonth();
      let year = today.getFullYear();
      this.time =
        hour.toString().padStart(2, "0") +
        ":" +
        minute.toString().padStart(2, "0");
      this.date = monthArr[month] + " " + day + ", " + year;
    },
  },
  mounted() {
    this.getTime();
    this.timeInterval = setInterval(() => {
      this.getTime();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timeInterval);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 50px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  >.time {
    border: 2px solid #3f3f42;
    border-radius: 12px;
    font-size: 2.188rem;
    color: #fff;
    letter-spacing: 0.06em;
    width: 11.688rem;
    font-weight: bold;
    opacity: 0;
  }

  >.logo {
    // width: 22.761rem;
    height: 3rem;
  }

  >.date {
    color: #f0dfbb;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 1.563rem;
    width: 11.688rem;

    >img {
      width: 0.313rem;
      height: 0.313rem;
    }
  }
}</style>
