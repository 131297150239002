<template>
  <div class="body_wrapper">
    <!-- <div class="title">
            <div class="hr"></div>
            <div class="content">NEW VISITOR</div>
            <div class="hr"></div>
        </div> -->

    <transition name="fade1"
      ><div id="logo-1" v-show="isIdle && idleStages.stage1" class="slide-item">
        <img src="../assets/logo.png" alt="" /></div
    ></transition>
    <transition name="fade1"
      ><div id="info-1" v-show="isIdle && idleStages.stage2" class="slide-item">
        <h2>Recent check-ins</h2>
        <div class="checkin-container">
          <div
            class="checkin-item"
            v-for="(item, index) in recentCheckIns"
            :key="index"
          >
            <div class="header">
              <div class="image">
                <img :src="item.image" alt="" />
                <div class="organization">
                  <img src="../assets/Logo.svg" alt="" />
                </div>
              </div>
              <div class="number" v-if="index == 0">01</div>
              <div class="number" v-if="index == 1">02</div>
              <div class="number" v-if="index == 2">03</div>
            </div>
            <div class="name">{{ formatName(item.fullname.trim()) }}</div>
            <div class="time">{{ formatTime(item.current_execute) }}</div>
          </div>
        </div>
      </div></transition
    >
    <transition name="fade1"
      ><div
        id="leaderboard-1"
        v-show="isIdle && idleStages.stage3"
        class="slide-item"
      >
        <h2>XP Leaderboard</h2>
        <div class="leaderboard-container">
          <table>
            <tr class="item" v-for="(item, index) in leaderBoard1" :key="index">
              <td class="ranking" v-if="index == 0">1ST</td>
              <td class="ranking" v-if="index == 1">2ND</td>
              <td class="ranking" v-if="index == 2">3RD</td>
              <td class="ranking" v-if="index == 3">4TH</td>
              <td class="ranking" v-if="index == 4">5TH</td>

              <td class="name">
                <img
                  :src="
                    item.image
                      ? item.image
                      : 'https://assets.weepaz.com/assets/level0.jpg'
                  "
                  alt=""
                />
                {{ item.fullname }}
              </td>
              <td class="point">
                <div>
                  <img src="../assets/XP.svg" alt="" />
                  {{ item.lp }}
                </div>
                <div>
                  <img src="../assets/USD.svg" alt="" />
                  {{ item.rp }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div></transition
    >
    <transition name="fade1"
      ><div id="info-2" v-show="isIdle && idleStages.stage4" class="slide-item">
        <h2>Recent check-outs</h2>
        <div class="checkin-container">
          <div
            class="checkin-item"
            v-for="(item, index) in recentCheckOuts"
            :key="index"
          >
            <div class="header">
              <div class="image">
                <img :src="item.image" alt="" />
                <div class="organization">
                  <img src="../assets/Logo.svg" alt="" />
                </div>
              </div>
              <div class="number" v-if="index == 0">01</div>
              <div class="number" v-if="index == 1">02</div>
              <div class="number" v-if="index == 2">03</div>
            </div>
            <div class="name">{{ formatName(item.fullname.trim()) }}</div>
            <div class="time">{{ formatTime(item.current_execute) }}</div>
          </div>
        </div>
      </div></transition
    >
    <transition name="fade1">
      <div id="news-1" v-show="isIdle && idleStages.stage5" class="slide-item">
        <h2>What’s new</h2>
        <div class="news-container" v-if="news[0]">
          <h3>{{ news[0].title }}</h3>
          <div class="news-content">
            <img
              :src="
                'https://bulletin-admin.whydah.xyz' +
                news[0].thumbnail.data.attributes.url
              "
              alt=""
            />
            <p>{{ news[0].content }}</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade1"
      ><div id="logo-2" v-show="isIdle && idleStages.stage6" class="slide-item">
        <img src="../assets/logo.png" alt="" /></div
    ></transition>

    <transition name="fade1"
      ><div
        id="leaderboard-2"
        v-show="isIdle && idleStages.stage7"
        class="slide-item"
      >
        <h2>Level+XP Leaderboard</h2>
        <div class="leaderboard-container">
          <table>
            <tr class="item" v-for="(item, index) in leaderBoard2" :key="index">
              <td class="ranking" v-if="index == 0">1ST</td>
              <td class="ranking" v-if="index == 1">2ND</td>
              <td class="ranking" v-if="index == 2">3RD</td>
              <td class="ranking" v-if="index == 3">4TH</td>
              <td class="ranking" v-if="index == 4">5TH</td>

              <td class="name">
                <img
                  :src="
                    item.image
                      ? item.image
                      : 'https://assets.weepaz.com/assets/level0.jpg'
                  "
                  alt=""
                />
                {{ item.fullname }}
              </td>
              <td class="point">
                <div>{{ item.level }}</div>
                <div>
                  <img src="../assets/XP.svg" alt="" />
                  {{ item.lp }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div></transition
    >
    <transition name="fade1"
      ><div
        id="leaderboard-3"
        v-show="isIdle && idleStages.stage8"
        class="slide-item"
      >
        <h2>
          Earliest check-ins <br />
          this week
        </h2>
        <div class="leaderboard-container">
          <table>
            <tr
              class="item"
              v-for="(item, index) in earliestCheckIns"
              :key="index"
            >
              <td class="ranking" v-if="index == 0">1ST</td>
              <td class="ranking" v-if="index == 1">2ND</td>
              <td class="ranking" v-if="index == 2">3RD</td>
              <td class="ranking" v-if="index == 3">4TH</td>
              <td class="ranking" v-if="index == 4">5TH</td>

              <td class="name">
                <img
                  :src="
                    item.image
                      ? item.image
                      : 'https://assets.weepaz.com/assets/level0.jpg'
                  "
                  alt=""
                />
                {{ item.fullname }}
              </td>
              <td class="point">
                <div>{{ item.avg_time.substring(0, 5) }}</div>
                <div>
                  {{ item.checkin_day_count }}
                  {{ item.checkin_day_count == 1 ? "day" : "days" }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div></transition
    >
    <transition name="fade1"
      ><div
        id="leaderboard-4"
        v-show="isIdle && idleStages.stage9"
        class="slide-item"
      >
        <h2>
          Latest check-ins <br />
          this week
        </h2>
        <div class="leaderboard-container">
          <table>
            <tr
              class="item"
              v-for="(item, index) in latestCheckIns"
              :key="index"
            >
              <td class="ranking" v-if="index == 0">1ST</td>
              <td class="ranking" v-if="index == 1">2ND</td>
              <td class="ranking" v-if="index == 2">3RD</td>
              <td class="ranking" v-if="index == 3">4TH</td>
              <td class="ranking" v-if="index == 4">5TH</td>

              <td class="name">
                <img
                  :src="
                    item.image
                      ? item.image
                      : 'https://assets.weepaz.com/assets/level0.jpg'
                  "
                  alt=""
                />
                {{ item.fullname }}
              </td>
              <td class="point">
                <div>{{ item.avg_time.substring(0, 5) }}</div>
                <div>
                  {{ item.checkin_day_count }}
                  {{ item.checkin_day_count == 1 ? "day" : "days" }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div></transition
    >
    <transition name="fade1">
      <div id="news-1" v-show="isIdle && idleStages.stage10" class="slide-item">
        <h2>What’s new</h2>
        <div class="news-container" v-if="news[1]">
          <h3>{{ news[1].title }}</h3>
          <div class="news-content">
            <img
              :src="
                'https://bulletin-admin.whydah.xyz' +
                news[1].thumbnail.data.attributes.url
              "
              alt=""
            />
            <p>{{ news[1].content }}</p>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div id="recent-person" v-show="!isIdle">
        <div
          class="person-container"
          :class="[
            list[0].metadata.kind == 1
              ? 'red'
              : list[0].metadata.kind == 2
              ? 'orange'
              : list[0].metadata.kind == 3
              ? 'green'
              : list[0].metadata.kind == 4
              ? 'blue'
              : '',
          ]"
        >
          <transition name="fade">
            <div class="popup" v-if="temp2">
              <div class="notice">[ Authentication Successful ]</div>
              <div class="welcome">welcome to whydah spaceship!</div>
            </div>
          </transition>
          <div class="picture-container">
            <!-- <img src="../assets/profile_picture.png" alt="" /> -->
            <img class="loadingAvatar" src="../assets/Avatar.png" alt="" />

            <transition name="fade">
              <img
                class="avatarMember"
                :src="list[0].metadata.image"
                alt=""
                v-if="temp && list[0].metadata.image"
              />
              <img
                class="avatarMember"
                src="../assets/profile_picture.png"
                alt=""
                v-if="temp && !list[0].metadata.image"
              />
            </transition>
          </div>
          <div class="name-container">
            <transition name="fade">
              <div class="title" v-if="list[0].metadata.kind == 5">
                Ambassador
              </div>
              <div class="title" v-if="temp">
                ASTRONAUT -
                {{
                  list[0].metadata.kind == 1
                    ? "Media"
                    : list[0].metadata.kind == 2
                    ? "VIP"
                    : list[0].metadata.level
                }}
              </div></transition
            >
            <transition name="fade">
              <div class="fullname" v-if="temp && list">
                {{ formatName(list[0].metadata.fullname.trim()) }}
              </div>
            </transition>
            <!-- <transition name="fade">
              <div class="fullname" v-if="!temp"></div>
            </transition> -->
            <transition name="fade">
              <div class="total-balance" v-if="temp">
                <div>
                  <img src="../assets/XP.svg" alt="" /><span>{{
                    list[0].metadata.lp
                  }}</span>
                  <span class="plus" v-if="totalRewardLp"
                    >+{{ totalRewardLp }}</span
                  >
                </div>
                &nbsp;
                <div>
                  <img src="../assets/USD.svg" alt="" /><span>{{
                    list[0].metadata.rp
                  }}</span>
                  <span class="plus" v-if="totalRewardRp"
                    >+{{ totalRewardRp }}</span
                  >
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div class="team" v-if="temp">WHYDAH'S CREW</div></transition
            >
          </div>
          <transition name="fade">
            <div class="mission-container" v-if="temp">
              <div
                class="mission"
                v-if="list[0].response_execute_mission.length == 0"
              >
                <span class="mission-name">{{
                  list[0].action == "checkin"
                    ? "check in"
                    : list[0].action == "checkout"
                    ? "check out"
                    : list[0].action
                }}</span>
              </div>
              <div
                class="mission"
                v-else
                v-for="(mission, index) in list[0].response_execute_mission"
                :key="index"
              >
                <span class="mission-name">{{ mission.mission_name }}</span>
                <span class="mission-progress" v-if="mission.lp || mission.rp"
                  ><div v-if="mission.lp">
                    <img src="../assets/XP.svg" alt="" />+{{ mission.lp }}
                  </div>
                  <div v-if="mission.rp">
                    <img src="../assets/USD.svg" alt="" />+{{ mission.rp }}
                  </div>
                </span>
              </div>
            </div>
          </transition>
        </div>
      </div></transition
    >
    <!-- <div id="recent-list">
      <div class="title">RECENT ACTIVITY</div>
      <div class="content-container" v-if="list.length > 0">
        <TransitionGroup tag="" name="slide-fade" class="transition">
          <div
            v-for="item in list"
            :key="item.current_execute"
            class="container"
          >
            <div class="picture">
              <img :src="item.image" alt="" />
            </div>
            <div class="name">{{ item.fullname }}</div>
            <div class="mission">{{ item.mission_name }}</div>
            <div class="time" v-if="item.current_execute < 10">{ TIME }</div>
            <div class="time" v-if="item.current_execute > 10">
              {{ formatTime(item.current_execute) }}
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
      listFromSse: new Array(),
      list: [
        {
          metadata: {
            kind: 0,
            image: "",
            fullname: "{NAME}",
            level: "{LEVEL}",
            lp: 1,
            rp: 1,
          },
          response_execute_mission: [
            { mission_name: "{MISSION}", lp: 1, rp: 2 },
          ],
        },
      ],
      leaderBoard1: new Array(),
      leaderBoard2: new Array(),
      recentCheckIns: new Array(),
      recentCheckOuts: new Array(),
      earliestCheckIns: new Array(),
      latestCheckIns: new Array(),
      news: new Array(),
      totalRewardLp: 0,
      totalRewardRp: 0,
      wait: false, //  check in queue show each person at least 4s
      isIdle: true, // idle slides
      // idleStages: {
      //   stage1: true,
      //   stage2: false,
      //   stage3: false,
      //   stage4: false,
      //   stage5: false,
      //   stage6: false,
      // },
      idleStages: new Object(),
      totalSlides: 0,
      activeSlide: 1,
      interval: null,
      temp: true,
      temp2: false,
      timeOutTemp2: null,
      timeOutIdle: null,
      idlePause: false,
      idleInterval: null,
    };
  },
  methods: {
    updateList(x) {
      // Reset variables
      this.totalRewardLp = 0;
      this.totalRewardRp = 0;

      /* for UI transition */

      this.temp = false;
      clearTimeout(this.timeOutTemp2);
      this.temp2 = false;
      setTimeout(() => {
        this.temp2 = true;
      }, 800);
      setTimeout(() => {
        this.temp = true;
      }, 500);
      this.timeOutTemp2 = setTimeout(() => {
        this.temp2 = false;
      }, 5000);

      // time out for idle screen
      clearTimeout(this.timeOutIdle);
      this.isIdle = false;
      this.timeOutIdle = setTimeout(() => {
        this.isIdle = true;
      }, 30000);

      // fetch new leaderboard, checkins info
      setTimeout(async () => {
        await this.getLeaderboard1();
        await this.getLeaderboard2();
        await this.getRecentCheckIns();
        await this.getRecentCheckOuts();
        await this.getEarliestCheckIns();
        await this.getLatestCheckIns();
        await this.getNews();
      }, 2000);

      //   let temp = {
      //     fullname: x.metadata.fullname,
      //     image: x.metadata.image,
      //     response_execute_mission: x.response_execute_mission,
      //     // mission_name: x.mission_name,
      //     // goal: x.goal,
      //     // total: x.total,
      //     level: x.metadata.level,
      //     lp: x.metadata.lp,
      //     rp: x.metadata.rp,
      //   };
      // let temp = x;
      x.response_execute_mission.forEach((element) => {
        this.totalRewardLp += element.lp;
        this.totalRewardRp += element.rp;
      });

      this.list.unshift(x);
      //   this.u.fullname = this.u.fullname + 1;
      this.list.pop();
    },
    formatTime(x) {
      let today = new Date();
      let todate = today.getDate();
      let time = new Date(x);
      let month = time.getMonth() + 1;
      let date = time.getDate();
      let minute = time.getMinutes();
      let hour = time.getHours();
      if (todate != date) {
        return (
          date.toString().padStart(2, "0") +
          "-" +
          month.toString().padStart(2, "0")
        );
      } else
        return (
          hour.toString().padStart(2, "0") +
          ":" +
          minute.toString().padStart(2, "0")
        );
    },
    formatName(x) {
      let array = x.split(" ");
      // console.log(array);
      if (array.length <= 2) {
        return x;
      } else {
        return array[array.length - 1] + " " + array[0];
      }
    },

    changeSlideNext() {
      if (this.idleStages["stage" + this.activeSlide] == true) {
        this.idleStages["stage" + this.activeSlide] = false;
        if (this.activeSlide == this.totalSlides) {
          this.idleStages["stage1"] = true;
          this.activeSlide = 1;
        } else {
          this.idleStages["stage" + (this.activeSlide + 1)] = true;
          this.activeSlide++;
        }
        this.$forceUpdate();
      }
    },
    changeSlidePrev() {
      if (this.idleStages["stage" + this.activeSlide] == true) {
        this.idleStages["stage" + this.activeSlide] = false;
        if (this.activeSlide == 1) {
          this.idleStages["stage" + this.totalSlides] = true;
          this.activeSlide = this.totalSlides;
        } else {
          this.idleStages["stage" + (this.activeSlide - 1)] = true;
          this.activeSlide--;
        }
        this.$forceUpdate();
      }
      // if (this.idleStages.stage6) {
      //   this.idleStages.stage6 = false;
      //   this.idleStages.stage5 = true;
      // } else if (this.idleStages.stage5) {
      //   this.idleStages.stage5 = false;
      //   this.idleStages.stage4 = true;
      // } else if (this.idleStages.stage4) {
      //   this.idleStages.stage4 = false;
      //   this.idleStages.stage3 = true;
      // } else if (this.idleStages.stage3) {
      //   this.idleStages.stage3 = false;
      //   this.idleStages.stage2 = true;
      // } else if (this.idleStages.stage2) {
      //   this.idleStages.stage2 = false;
      //   this.idleStages.stage1 = true;
      // } else if (this.idleStages.stage1) {
      //   this.idleStages.stage1 = false;
      //   this.idleStages.stage6 = true;
      // }
    },
    getNumberOfElements() {
      const arr = document.getElementsByClassName("slide-item");
      this.totalSlides = arr.length;
      for (let i = 1; i <= arr.length; i++) {
        if (i == 1) {
          this.idleStages["stage" + i] = true;
        } else {
          this.idleStages["stage" + i] = false;
        }
      }
    },

    async getList() {
      await axios
        .get(
          "https://backend-dev.weepaz.com/api/v1/mission/latest-mission-data"
        )
        .then((respone) => {
          // console.log(respone.data);
          this.list = respone.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getLeaderboard1() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/v1/auth/leaderboard?limit=5&filter=1`
        )
        .then((response) => {
          this.leaderBoard1 = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLeaderboard2() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/v1/auth/leaderboard?limit=5&filter=2`
        )
        .then((response) => {
          this.leaderBoard2 = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getRecentCheckIns() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/auth/last/checkin`)
        .then((response) => {
          this.recentCheckIns = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getRecentCheckOuts() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/v1/auth/last/checkout`)
        .then((response) => {
          this.recentCheckOuts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getEarliestCheckIns() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/v1/auth/get-avg-checkin-out-in-week?limit=5&order_by=ASC`
        )
        .then((response) => {
          // console.log(response.data);
          this.earliestCheckIns = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getLatestCheckIns() {
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/v1/auth/get-avg-checkin-out-in-week?limit=5&order_by=DESC`
        )
        .then((response) => {
          // console.log(response.data);
          this.latestCheckIns = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getNews() {
      await axios
        .get(
          "https://bulletin-admin.whydah.xyz/api/news?populate=*&sort=date%3Adesc",
          {
            headers: {
              Authorization:
                "Bearer 5e191ca4a64b13525df47f477aa25f87dcf0be4f9bc600db4b2ac6dd7e144eb198177f0a3811746917db3984f95ad922e8a284d2a8388ef74183829e48545d1fa0833ef71cb2c7787dbb2660b18908654f2fcfa71be5590a62ea6be831e4562fea6f52f446072fed6d38f4bfeda3c0e41f5595fb6cf4a77f782a66d13de718f7",
            },
          }
        )
        .then((respone) => {
          const listNews = [
            respone.data.data[0].attributes,
            respone.data.data[1].attributes,
          ];
          this.news = listNews;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    connectSSE() {
      this.$sse
        .create(`${process.env.VUE_APP_API_URL}/api/v1/streaming`)
        .on("message", (msg) => {
          // console.log(msg);
          this.listFromSse.push(msg);
          // this.updateList(msg.wallet_address);
        })
        .on("error", (err) => {
          console.error("Failed to parse or lost connection:", err);
          setTimeout(() => {
            this.connectSSE();
          }, 300000);
        })
        .connect()
        .catch((err) => {
          console.error("Failed make initial connection:", err);
          setTimeout(() => {
            this.connectSSE();
          }, 300000);
        });
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.getNumberOfElements();
    });
    await this.getNews();
    await this.getLeaderboard1();
    await this.getLeaderboard2();
    await this.getRecentCheckIns();
    await this.getRecentCheckOuts();
    await this.getEarliestCheckIns();
    await this.getLatestCheckIns();
    this.idleInterval = setInterval(() => {
      this.changeSlideNext();
    }, 60000);
    // this.updateList(this.u);
    // this.getList();
    document.addEventListener("keydown", (e) => {
      e = e || window.event;
      if (e.key === "ArrowRight") {
        this.changeSlideNext();
      } else if (e.key === "ArrowLeft") {
        this.changeSlidePrev();
      } else if (e.key === " ") {
        if (this.idlePause) {
          clearInterval(this.idleInterval);
          this.idleInterval = setInterval(() => {
            this.changeSlideNext();
          }, 120000);
        } else if (!this.idlePause) {
          clearInterval(this.idleInterval);
        }
        this.idlePause = !this.idlePause;
      }
    });
    this.connectSSE();
    // this.interval = setInterval(() => {
    //   this.$forceUpdate();
    // }, 30000);
  },
  beforeDestroy() {
    this.$sse.disconnect();
    // clearInterval(this.interval);
  },
  destroyed() {
    document.removeEventListener("keydown", (e) => {
      e = e || window.event;
      if (e.key === "ArrowRight") {
        this.changeSlideNext();
      } else if (e.key === "ArrowLeft") {
        this.changeSlidePrev();
      } else if (e.key === " ") {
        if (this.idlePause) {
          clearInterval(this.idleInterval);
          this.idleInterval = setInterval(() => {
            this.changeSlideNext();
          }, 120000);
        } else if (!this.idlePause) {
          clearInterval(this.idleInterval);
        }
        this.idlePause = !this.idlePause;
      }
    });
    clearInterval(this.idleInterval);
  },
  watch: {
    listFromSse() {
      // console.log("list from sse", this.listFromSse.length);
      // if list isn't empty and not waiting 3s
      if (this.listFromSse.length > 0 && this.wait == false) {
        this.updateList(this.listFromSse.shift());
        this.wait = true;
        setTimeout(() => {
          this.wait = false;
        }, 4000);
      } else return;
    },
    wait() {
      // console.log("wait", this.wait);
      if (this.wait == false && this.listFromSse.length > 0) {
        this.updateList(this.listFromSse.shift());
        this.wait = true;
        setTimeout(() => {
          this.wait = false;
        }, 4000);
      } else return;
    },
  },
};
</script>
<style>
/* html {
  font-size: calc((100vw / 1920) * 16);
} */
html {
  background-image: url("../assets/profile_frame.png"),
    url("../assets/profile_frame2.png"), url("../assets/profile_frame_red.png"),
    url("../assets/profile_frame_red2.png"),
    url("../assets/profile_frame_orange.png"),
    url("../assets/profile_frame_orange2.png"),
    url("../assets/profile_frame_green.png"),
    url("../assets/profile_frame_green2.png"),
    url("../assets/profile_frame_blue.png"),
    url("../assets/profile_frame_blue2.png");
  background-size: 0;
}
</style>
<style lang="scss" scoped>
.body_wrapper {
  // font-size: 100vw;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  > * {
    text-transform: uppercase;
  }

  > .title {
    top: 45%;
    left: -20vh;
    position: absolute;
    display: flex;
    align-items: center;
    column-gap: 1.875rem;
    transform: rotate(-90deg);
    > .hr {
      content: "";
      width: 20vh;
      height: 0.063rem;
      border-top: 0.125rem solid #3f3f42;
    }
    > .content {
      font-size: 2rem;
      line-height: 1rem;
      color: #ffffff;
    }
  }
  > #logo-1 {
    padding: 0rem 7rem;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../assets/overlay_background.gif") no-repeat center/ auto 110vh;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      z-index: 1;
      width: 80%;
    }
  }
  > #logo-2 {
    padding: 0rem 7rem;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("../assets/background(2).gif") no-repeat center/ auto 110vh;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 80%;
    }
  }
  > #leaderboard-1,
  #leaderboard-3 {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../assets/3.gif") no-repeat center / auto 110vh;
  }
  > #leaderboard-2,
  #leaderboard-4 {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../assets/overlay_background(1).gif") no-repeat center / auto 110vh;
  }
  > #leaderboard-1,
  #leaderboard-2,
  #leaderboard-3,
  #leaderboard-4 {
    padding: 0rem 7rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      max-width: 40rem;
      font-weight: 700;
      font-size: 3rem;
      line-height: 4rem;
      color: #ffffff;
      margin-bottom: 2rem;
    }
    .leaderboard-container {
      width: 100%;
      padding: 3rem 0 3rem 0;
      background: url("../assets/Leaderboard.png");
      background-size: 100% 100%;
      > table {
        width: 100%;
        > .item {
          display: flex;
          column-gap: 1rem;
          padding: 1.5rem 1.5rem;
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 2.25rem;
          color: #ffffff;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          &:last-of-type {
            border-bottom: none;
          }
          > .ranking {
            width: 10%;
          }
          > .name {
            width: 50%;
            text-align: left;
            > img {
              object-fit: cover;

              border-radius: 50%;
              height: 2rem;
              width: 2rem;
              border: 1px solid rgba(0, 0, 0, 0.2);
            }
          }
          > .point {
            flex: 1 1 0%;
            display: flex;
            align-items: center;
            column-gap: 3rem;
            > div {
              &:first-of-type {
                width: 35%;
              }
              // flex: 1 1 0%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              img {
                height: 2.25rem;
                width: 2.25rem;
              }
            }
          }
        }
      }
    }
  }
  > #info-1 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../assets/background(2).gif") no-repeat center / auto 110vh;
  }
  > #info-2 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../assets/2.gif") no-repeat center / auto 110vh;
  }
  > #info-1,
  #info-2 {
    padding: 0rem 7rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    //   url("../assets/background(2).gif") no-repeat center / auto 80rem;
    > * {
      transform: scale(0.8);
    }
    h2 {
      top: 2.5rem;
      position: relative;
      font-weight: 700;
      font-size: 3.875rem;
      line-height: 4.5rem;
      color: #ffffff;
      // margin-bottom: 3rem;
    }
    .checkin-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 2rem;
      .checkin-item {
        width: 27.5rem;
        padding: 1.5rem;
        background: url("../assets/checkinBG.png");
        background-size: 100% 100%;
        .header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1rem;
          .image {
            width: 8rem;
            height: 8rem;
            border: 1px solid #404040;
            border-radius: 16px;
            position: relative;
            > img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 16px;
            }
            > .organization {
              position: absolute;
              top: -15px;
              right: -38px;
              background: #000000;
              border: 6px solid #181818;
              border-radius: 2.25rem;
              width: 4.5rem;
              height: 4.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              > img {
                width: 70%;
              }
            }
          }
          > .number {
            font-weight: 700;
            font-size: 64px;
            line-height: 96px;
            color: #ffffff;
            opacity: 0.3;
          }
        }
        .name {
          font-weight: 700;
          font-size: 40px;
          line-height: 60px;
          color: #ffffff;
          margin-bottom: 2rem;
          text-align: start;
        }
        .time {
          font-weight: 700;
          font-size: 32px;
          line-height: 48px;
          color: #ffffff;
          text-align: start;
        }
      }
    }
  }
  > #news-1,
  #news2 {
    padding: 0rem 7rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../assets/5.gif") no-repeat center / 100% auto;
    > h2 {
      font-weight: 700;
      font-size: 3rem;
      line-height: 4rem;
      color: #ffffff;
      margin-bottom: 2rem;
    }
    > .news-container {
      width: 90%;
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 0 43px hsl(0deg 0% 100% / 5%),
        inset 0 2.54239px 2.54239px hsl(0deg 0% 100% / 15%);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      border-radius: 1.5rem;
      padding: 2.2rem;
      color: #ffffff;
      > h3 {
        font-weight: 600;
        font-size: 2.2rem;
        margin: 0;
        border-bottom: 1px solid #fff;
        width: 100%;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
      }
      > .news-content {
        display: flex;
        column-gap: 3rem;
        > img {
          width: 50%;
          height: auto;
        }
        > p {
          font-size: 1.3rem;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
  > #recent-person {
    // flex: 1 1 0%;
    width: 100vw;
    height: 100svh;
    justify-content: center;
    display: flex;
    align-items: center;
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      #000000 url("../assets/4.gif") no-repeat center / auto 100%;

    > .person-container {
      overflow: visible;
      position: relative;
      right: 20rem;

      &.red {
        .picture-container {
          background-image: url("../assets/profile_frame_red.png");
        }
        .name-container {
          background-image: url("../assets/profile_frame_red2.png");
          .title {
            color: #f56d6d !important;
          }
          .fullname {
            color: #f56d6d !important;
          }
          .total-balance {
            > div {
              color: #f56d6d !important;
              > img {
                filter: brightness(0) saturate(100%) invert(64%) sepia(5%)
                  saturate(7420%) hue-rotate(314deg) brightness(97%)
                  contrast(98%);
              }
            }
          }
          .team {
            color: #f56d6d !important;
          }
        }

        .mission-container {
          * {
            color: #f56d6d !important;
          }
          img {
            filter: brightness(0) saturate(100%) invert(64%) sepia(5%)
              saturate(7420%) hue-rotate(314deg) brightness(97%) contrast(98%);
          }
        }
      }

      &.orange {
        .picture-container {
          background-image: url("../assets/profile_frame_orange.png");
        }
        .name-container {
          background-image: url("../assets/profile_frame_orange2.png");

          .title {
            color: #b44e04 !important;
          }
          .fullname {
            color: #b44e04 !important;
          }
          .total-balance {
            > div {
              color: #b44e04 !important;
              > img {
                filter: brightness(0) saturate(100%) invert(31%) sepia(43%)
                  saturate(2056%) hue-rotate(359deg) brightness(99%)
                  contrast(100%);
              }
            }
          }
          .team {
            color: #b44e04 !important;
          }
        }

        .mission-container {
          * {
            color: #b44e04 !important;
          }
          img {
            filter: brightness(0) saturate(100%) invert(31%) sepia(43%)
              saturate(2056%) hue-rotate(359deg) brightness(99%) contrast(100%);
          }
        }
      }

      &.green {
        .picture-container {
          background-image: url("../assets/profile_frame_green.png");
        }
        .name-container {
          background-image: url("../assets/profile_frame_green2.png");

          .title {
            color: #0fe056 !important;
          }
          .fullname {
            color: #0fe056 !important;
          }
          .total-balance {
            > div {
              color: #0fe056 !important;
              > img {
                filter: brightness(0) saturate(100%) invert(71%) sepia(54%)
                  saturate(1816%) hue-rotate(83deg) brightness(93%)
                  contrast(95%);
              }
            }
          }
          .team {
            color: #0fe056 !important;
          }
        }

        .mission-container {
          * {
            color: #0fe056 !important;
          }
          img {
            filter: brightness(0) saturate(100%) invert(71%) sepia(54%)
              saturate(1816%) hue-rotate(83deg) brightness(93%) contrast(95%);
          }
        }
      }

      &.blue {
        .picture-container {
          background-image: url("../assets/profile_frame_blue.png");
        }
        .name-container {
          background-image: url("../assets/profile_frame_blue2.png");

          .title {
            color: #0b5985 !important;
          }
          .fullname {
            color: #0b5985 !important;
          }
          .total-balance {
            > div {
              color: #0b5985 !important;
              > img {
                filter: brightness(0) saturate(100%) invert(24%) sepia(52%)
                  saturate(1526%) hue-rotate(170deg) brightness(101%)
                  contrast(92%);
              }
            }
          }
          .team {
            color: #0b5985 !important;
          }
        }

        .mission-container {
          * {
            color: #0b5985 !important;
          }
          img {
            filter: brightness(0) saturate(100%) invert(24%) sepia(52%)
              saturate(1526%) hue-rotate(170deg) brightness(101%) contrast(92%);
          }
        }
      }

      > .popup {
        z-index: 11;
        position: absolute;
        width: 50rem;
        height: 7.313rem;
        border: 0.125rem solid #ffffff;
        background: rgba(0, 0, 0, 0.9) url("../assets/popup_bg.png");
        top: -4.375rem;
        left: 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 1.875rem;

        &::after {
          z-index: 0;
          content: "";
          top: 0px;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.9);
        }
        > .notice {
          z-index: 2;
          font-weight: 700;
          font-size: 1.813rem;
          line-height: 2.688rem;
          color: #ffffff;
          margin-bottom: 0.625rem;
          letter-spacing: 0.06em;
          animation: typing 1.3s;
          white-space: nowrap;
          animation-timing-function: steps(29, jump-end);
          overflow: hidden;
        }
        > .welcome {
          z-index: 2;
          font-weight: 700;
          font-size: 1rem;
          line-height: 1.275rem;
          letter-spacing: 0.06em;
          color: #ffffff;
          animation: typing 1.2s;
          white-space: nowrap;
          animation-timing-function: steps(28, jump-end);
          overflow: hidden;
        }

        // .fillBg {
        //   position: relative;
        //   height: 100%;
        // }
      }
      > .picture-container {
        background-image: url("../assets/profile_frame.png");
        transition: background-image 0.5s ease-in-out;
        background-size: 100% 100%;
        width: 36.233rem;
        height: 36.25rem;
        position: relative;
        z-index: 10;
        > img.loadingAvatar {
          overflow: hidden;
          object-fit: cover;
          position: absolute;
          left: 54%;
          top: 46%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          z-index: -1;
        }
        img.avatarMember {
          z-index: -1;
          border-radius: 50%;
          z-index: -1;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center;
          position: absolute;
          width: 70%;
          left: 53.7%;
          top: 45.5%;
          transform: translate(-50%, -50%);
        }
      }
      > .name-container {
        position: absolute;
        width: 586px;
        height: 256px;
        box-sizing: border-box;
        background-image: url("../assets/profile_frame2.png");
        transition: background-image 0.5s ease-in-out;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        padding: 3.313rem 0.844rem 0.75rem 8.3rem;
        top: 12%;
        left: 78%;
        > .title {
          font-size: 24px;
          line-height: 1.125rem;
          color: #fff;
          font-weight: bold;
          text-align: left;
          margin-bottom: 20px;
        }
        > .fullname {
          color: #ffffff;
          font-weight: bold;
          font-size: 3.375rem;
          line-height: 100%;
          text-align: left;
          flex: 1 1 0%;
        }
        > .team {
          text-align: right;
          font-size: 24px;
          line-height: 1.125rem;
          color: #fff;
          font-weight: bold;
        }
        > .total-balance {
          display: flex;
          column-gap: 1rem;
          justify-content: flex-end;
          align-items: center;
          padding-right: 1rem;
          margin-bottom: 1.5rem;
          > div {
            font-size: 28px;
            line-height: 2rem;
            color: #fff;
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            > img {
              height: 2rem;
            }
            > span {
              opacity: 0.6;
              &.plus {
                opacity: 1;
              }
            }
          }
        }
      }
      > .mission-container {
        position: absolute;
        // left: 36rem;
        right: -29rem;
        top: 23rem;
        display: flex;
        flex-direction: column;
        .mission {
          margin-bottom: 1.5rem;
          display: flex;
          align-items: center;
          column-gap: 1.5rem;
          line-height: 2rem;
          color: #fff;
          font-weight: bold;
          font-size: 2rem;
          justify-content: flex-end;
          max-width: 30rem;
          .mission-name {
            min-width: 15.5rem;
            word-break: break-word;
          }
        }
        .mission-progress {
          display: flex;
          align-items: center;
          column-gap: 1.5rem;

          > div {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            width: 5.5rem;
            > img {
              height: 1.5rem;
              width: 1.5rem;
            }
          }
        }
      }
    }
  }
  > #recent-list {
    flex: 1 1 0%;
    // width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > .title {
      width: 37.75rem;
      height: 2.625rem;
      box-sizing: border-box;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: rgba(240, 223, 187, 0.5);
      background-image: url("../assets/LeaderboardBG.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: left;
      padding: 0.5rem 0 0.25rem 1.5rem;
      margin-bottom: 1.5rem;
    }
    > .content-container {
      height: 100%;
      width: 80.625rem;
      border: 1px solid rgba(255, 255, 255, 0.5);
      // .transition {
      //   width: 100%;
      // }

      .container {
        width: 80.625rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-collapse: collapse;
        > div {
          padding: 0.75rem 0;
          gap: 1rem;
          &.picture {
            width: 10%;
            > img {
              width: 2rem;
              height: 2rem;
              border-radius: 50%;
            }
          }
          &.name {
            width: 35%;
            text-align: left;
            font-size: 1rem;
            line-height: 1.5rem;
            color: #ffffff;
          }
          &.mission,
          &.time {
            width: 30%;
            text-align: left;
            font-size: 1rem;
            line-height: 1.5rem;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}
.slide-fade-move,
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.slide-fade-leave-active {
}

/* 2. declare enter from and leave to state */
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(90px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
        animations can be calculated correctly. */
.slide-fade-leave-active {
  position: absolute;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade1-enter-active,
.fade1-leave-active {
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
  position: absolute;
}
.fade1-enter,
.fade1-leave-to {
  opacity: 0;
  // position: absolute;
  transform-origin: center;
}
.fade1-enter {
  transform: scale(0.95) translateY(10rem) translateZ(10rem);
  // transform: translateX(100vw);
}
.fade1-leave-to {
  transform: scale(2) translateY(20rem);

  // transform: translateX(-100vw);
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
